<template>
  <div id="legend">
    <el-form @submit.native.prevent ref="form">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Legend" name="legend">
          <el-form-item label="Show">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="legend.show"
            ></el-switch>
          </el-form-item>
          <el-form-item label="Show for single series">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="legend.showForSingleSeries"
            ></el-switch>
          </el-form-item>
          <el-tooltip
            class="item"
            effect="dark"
            content="Make the legend floating on the chart that do not take space"
            placement="left-start"
          >
            <el-form-item label="Floating Legend">
              <el-switch
                inactive-color="#24272b"
                @change="updateChart"
                v-model="legend.floating"
              ></el-switch>
            </el-form-item>
          </el-tooltip>

          <el-form-item class="mt2" label=" Position">
            <el-radio-group
              @change="updateChart"
              size="small"
              v-model="legend.position"
            >
              <el-radio-button label="top">Top</el-radio-button>
              <el-radio-button label="bottom">Bottom</el-radio-button>
              <el-radio-button label="left">Left</el-radio-button>
              <el-radio-button label="right">Right</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            v-if="legend.position === 'bottom' || legend.position === 'top'"
            class="mt2"
            label=" Position"
          >
            <el-radio-group
              @change="updateChart"
              size="small"
              v-model="legend.horizontalAlign"
            >
              <el-radio-button label="left">Left</el-radio-button>
              <el-radio-button label="center">Center</el-radio-button>
              <el-radio-button label="right">Right</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <h4>Left Offset</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="legend.offsetX"
              :min="-100"
              :max="100"
            ></el-slider>
          </div>

          <h4>Top Offset</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="legend.offsetY"
              :min="-100"
              :max="100"
            ></el-slider>
          </div>

          <h4>Legend Horizontal Spacing</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="legend.itemMargin.horizontal"
              :min="0"
              :max="50"
            ></el-slider>
          </div>

          <h4>Legend Vertical Spacing</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="legend.itemMargin.vertical"
              :min="0"
              :max="50"
            ></el-slider>
          </div>

          <h4>Font Size</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="legend.fontSize"
              :min="2"
              :max="80"
              show-input
            ></el-slider>
          </div>

          <h4>Font Weight</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="legend.fontWeight"
              :min="100"
              :step="100"
              :max="900"
            ></el-slider>
          </div>

          <el-collapse>
            <el-collapse-item title="Markers" name="markers">
              <h4>Marker Width</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="legend.markers.width"
                  :min="0"
                  :max="50"
                  show-input
                ></el-slider>
              </div>

              <h4>Marker Height</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="legend.markers.height"
                  :min="0"
                  :max="50"
                  show-input
                ></el-slider>
              </div>

              <el-form-item class="mt3" label="Marker Border Color">
                <extended-el-color-picker
                  @change="updateChart"
                  v-model="legend.markers.strokeColor"
                ></extended-el-color-picker>
              </el-form-item>

              <h4>Marker Border Width</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="legend.markers.strokeWidth"
                  :min="0"
                  :max="20"
                  show-input
                ></el-slider>
              </div>

              <h4>Marker Border Radius</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="legend.markers.radius"
                  :min="0"
                  :max="50"
                  show-input
                ></el-slider>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["legend"],
      legend: {
        show: true,
        showForSingleSeries: false,
        floating: false,
        position: "bottom", //top, bottom, left, right
        horizontalAlign: "center", // when position top/bottom -> left, right or center
        fontSize: 14,
        fontWeight: 400,
        width: undefined,
        height: undefined,
        offsetX: -20,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          fillColors: undefined,
          strokeColor: "#fff",
          radius: 12,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProps) {
      this.setNewOptions(newProps.legend);
    },
  },
  mounted: function() {
    const l = this.getChartConfig().w.config.legend;
    this.setNewOptions(l);
  },
  methods: {
    setNewOptions(l) {
      this.legend = {
        show: l.show,
        showForSingleSeries: l.showForSingleSeries,
        floating: l.floating,
        position: l.position, //top, bottom, left, right
        horizontalAlign: l.horizontalAlign, // left, right or center
        fontSize: l.fontSize,
        fontWeight: l.fontWeight,
        offsetX: l.offsetX,
        offsetY: l.offsetY,
        labels: {
          colors: l.labels.colors,
          useSeriesColors: l.labels.useSeriesColors,
        },
        markers: {
          width: l.markers.width,
          height: l.markers.height,
          strokeWidth: l.markers.strokeWidth,
          strokeColor: l.markers.strokeColor,
          radius: l.markers.radius,
        },
        itemMargin: {
          horizontal: l.itemMargin.horizontal,
          vertical: l.itemMargin.vertical,
        },
      };
    },

    updateChart: function() {
      const l = this.legend;
      this.updateChartOptions({
        legend: {
          show: l.show,
          showForSingleSeries: l.showForSingleSeries,
          floating: l.floating,
          position: l.position, //top, bottom, left, right
          horizontalAlign: l.horizontalAlign, // left, right or center
          fontSize: l.fontSize,
          fontWeight: l.fontWeight,
          offsetX: l.offsetX,
          offsetY: l.offsetY,
          labels: {
            colors: l.labels.colors,
            useSeriesColors: l.labels.useSeriesColors,
          },
          markers: {
            width: l.markers.width,
            height: l.markers.height,
            strokeWidth: l.markers.strokeWidth,
            strokeColor: l.markers.strokeColor,
            radius: l.markers.radius,
          },
          itemMargin: {
            horizontal: l.itemMargin.horizontal,
            vertical: l.itemMargin.vertical,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
